@media screen and (max-width: 480px) {
  .video-container {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.playerWrapper {
  position: relative;
  z-index: 1;
  max-width: 1920px;
  max-height: 745px;
  width: 100%;
  /* height: calc(100vh - 72px - 84px - 90px); */
  padding-top: 57%;
  margin: 0 auto;
  background: #0c4159;

  /* @media screen and (max-width: 480px) {
    height: auto;
  } */
}

.playerContainer {
  display: flex;
  /* height: 100%; */
  /* width: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* @media screen and (max-width: 1100px) {
    flex-direction: column;
  } */
}

.player {
  height: 100%;
  width: 100%;
  flex: 1.75;
  background-color: #151515;
}

.playeriframe {
  background-color: #151515;
  height: 100%;
  width: 100%;
}